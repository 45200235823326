<template>
  <transition
    enter-active-class="tw-transition tw-duration-100 tw-ease-out"
    enter-class="tw-opacity-0"
    enter-to-class="opacity-1"
    leave-active-class="tw-transition tw-duration-100 tw-ease-out"
    leave-class="opacity-1"
    leave-to-class="tw-opacity-0"
  >
    <div
      class="tw-fixed tw-inset-0 tw-overflow-x-hidden tw-overflow-y-auto z-60"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="tw-fixed tw-inset-0 tw-transition-opacity tw-bg-gray-500 tw-bg-opacity-25"
        aria-hidden="true"
        @click="closeModal"
      ></div>

      <transition
        enter-active-class="tw-transition tw-duration-200 tw-ease-in-out"
        enter-class="tw-transform tw-translate-x-36"
        enter-to-class="tw-transform tw-translate-x-0"
        leave-active-class="tw-transition tw-duration-200 tw-ease-in-out"
        leave-class="tw-transform tw-translate-x-36"
        leave-to-class="tw-translate-x-36"
        appear
      >
        <div
          class="tw-relative tw-w-full tw-h-full tw-max-w-2xl tw-px-4 tw-pt-5 tw-pb-4 tw-ml-auto tw-overflow-hidden tw-text-left tw-transition-all tw-transform tw-bg-white tw-rounded-sm tw-shadow-xl sm:tw-p-6"
        >
          <div class="tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
            <button
              type="button"
              class="tw-text-gray-400 tw-bg-white tw-rounded-md hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
              @click="closeModal"
            >
              <span class="tw-sr-only">Close</span>
              <svg
                class="tw-w-6 tw-h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-h-full">
            <h3 class="tw-mb-6 tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
              {{ heading }}
            </h3>
            <slot :close="closeModal"></slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>

export default {
  name: "SlideOutModal",
  props: ["heading", "generics"],
  components: {
  },
  data: function() {
    return {};
  },
  methods: {
    closeModal() {
      document.querySelector("body").classList.remove("no-v-scroll");
      this.$emit("closemodal");
    }
  },
  mounted() {
    document.querySelector("body").classList.add("no-v-scroll");
  }
};
</script>
