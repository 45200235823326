<template>
    <div class="tw-rounded-md">
        <div v-for="option, i in options" v-bind:key="option[id]" class="tw-flex tw-flex-row tw-p-4"
            :class="(checked(option.label) ? 'tw-bg-orange-50 tw-border-orange-200 tw-z-10 ' : 'tw-border-gray-100 ') + (i === 0 ? 'tw-rounded-tl-md tw-rounded-tr-md ' : '') + (i === options.length - 1 ? 'tw-rounded-bl-md tw-rounded-br-md ' : '') + 'tw-border tw-relative tw-p-4 tw-flex tw-cursor-pointer focus:outline-none'"
            tabindex="0" @click="onChange(option)"
        >
            <div class="tw-flex tw-flex-col tw-pb-6 tw-justify-center tw-items-center">
                <slot name="icon">
                    <span
                    :class="(checked(option.label) ? 'tw-bg-orange-600 tw-border-transparent ' : 'tw-bg-white tw-border-gray-300 ') + 'tw-h-4 tw-w-4 tw-mt-0.5 tw-cursor-pointer tw-rounded-full tw-border tw-flex tw-items-center tw-justify-center'"
                    aria-hidden="true"
                    >
                        <span className="tw-rounded-full tw-bg-white tw-w-2 tw-h-2" />
                    </span>
                </slot>
            </div>

            <div class="tw-flex-grow tw-py-1">
                <slot name="option" v-bind="{ option }">
                    <span>{{ option.label }}</span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "RadioGroup",
    props: ["selection", "options", "id"],
    methods: {
        checked(label) {
            if (this.selection) {
                return this.selection.label == label
            }
            return false;
        },
        onChange(newSelection) {
            this.$emit("change", newSelection);
        }
    }
}
</script>
