<template>
  <div
    class=""
  >
    <div v-if="small_screen">
      <h3 class="user-heading tw-text-lg">Current Members</h3>
      <UserCard
        :loading="isLoading && team.length === 0"
        v-for="user in team"
        v-bind:key="user.id"
        :user="user"
        @openuser="openUserEdit"
      />

      <h3 class="user-heading tw-text-lg">Invited Members</h3>
      <InvitedUserCard
        v-for="user in invited_users"
        v-bind:key="user.id"
        :user="user"
      />
    </div>

    <div v-else>
      <h3 class="user-heading tw-text-lg">Current Members</h3>
      <UsersTable :loading="false" :users="team" @openuser="openUserEdit"/>

      <h3 class="user-heading tw-text-lg">Invited Members</h3>
      <UsersTable :loading="false" :users="invited_users" />
    </div>
  </div>
</template>

<script>
import UserCard from "@/components/Team/UserCard.vue";
import UsersTable from "@/components/Team/UsersTable";
import InvitedUserCard from "@/components/Team/InvitedUserCard.vue";
import { mapGetters } from 'vuex'

export default {
  props: ["invited_users"],
  components: {
    UserCard,
    InvitedUserCard,
    UsersTable
  },
  data() {
    return {
      isLoading: true,
      team: [],
      screen_width: window.innerWidth
    };
  },
  computed: {
    ...mapGetters('user', ['currentTeam']),
    small_screen() {
      return this.screen_width < 800;
    }
  },
  methods: {
    async getUsers() {
        try {
            let uri = `${process.env.VUE_APP_API_ROOT}teams/${this.currentTeam.id}/users/`;
            var response = await this.$http.get(uri);
            this.team = response.data;
            this.isLoading = false;
        } catch (e) {
            // handle the authentication error here
        }
    },
    openUserEdit (val) {
      this.$emit('openuser', val);
    }
  },
  created() {
    this.getUsers();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screen_width = window.innerWidth;
    });
  }
};
</script>

<style>
.user-heading {
  margin-top: 20px;
}
</style>
