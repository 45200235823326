<template>
  <div>
    <TableContainer>
      <template v-slot:header>
        <TableHeaderItem>
          Name
        </TableHeaderItem>
        <TableHeaderItem>
          Email
        </TableHeaderItem>
        <TableHeaderItem>
          Role
        </TableHeaderItem>
        <TableHeaderItem> </TableHeaderItem>
      </template>

      <template v-slot:rows>
        <div class="my-3"></div>
        <TableRow
          v-for="user in team_members"
          :key="'user' + user.id"
          @click="toggleMemberModal(user)"
        >
          <TableField>
            <div class="tw-inline-flex">
              <Avatar
                v-if="!loading"
                :username="`${user.first_name} ${user.last_name}`"
                :size="30"
                class="flex-shrink-0 w-10 h-10 mr-2 rounded-full"
              ></Avatar>
              <span
                class="my-auto font-normal text-gray-500 hover:text-gray-700"
              >
                {{ `${user.first_name} ${user.last_name}` }}
              </span>
            </div>
          </TableField>
          <TableField>
            <a
              v-if="!loading"
              :href="`mailto:${user.email}`"
              class="text-sm font-normal text-gray-500 hover:text-gray-700"
            >
              {{ user.email }}
            </a>
          </TableField>
          <TableField>
            <a
              v-if="!loading"
              class="text-sm font-normal text-gray-500 hover:text-gray-700"
              >Member
            </a>
          </TableField>
          <TableField></TableField>
        </TableRow>
      </template>
    </TableContainer>
    <SlideOutModal
      heading="Team Member Details"
      v-if="member_modal"
      v-on:closemodal="toggleMemberModal"
    >
      <TeamMemberEdit :user="clicked_user" />
    </SlideOutModal>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import TableContainer from "@/components/ui/Table/TableContainer.vue";
import TableHeaderItem from "@/components/ui/Table/TableHeaderItem.vue";
import TableField from "@/components/ui/Table/TableField.vue";
import TableRow from "@/components/ui/Table/TableRow.vue";
import SlideOutModal from "@/components/ui/modals/SlideOutModal.vue";
import TeamMemberEdit from "@/components/Team/TeamMemberEdit.vue";

export default {
  name: "UserTable",
  props: ["team_members", "loading"],
  components: {
    Avatar,
    TableContainer,
    TableHeaderItem,
    TableField,
    TableRow,
    SlideOutModal,
    TeamMemberEdit
  },
  data() {
    return {
      member_modal: false,
      clicked_user: {}
    };
  },
  methods: {
    toggleMemberModal(user = {}) {
      this.clicked_user = user;
      this.member_modal = !this.member_modal;
    }
  }
};
</script>

<style></style>
