<template>
  <div
    class="tw-col-span-1 tw-my-5 tw-bg-white tw-rounded-lg tw-shadow tw-divide-y tw-divide-gray-200"
  >
    <div
      class="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-6 tw-space-x-6"
    >
      <div class="tw-flex-1 tw-truncate">
        <div
          v-if="loading"
          class="tw-animate-pulse tw-flex tw-items-center tw-space-x-3"
        >
          <div class="tw-max-w-sm tw-w-full">
            <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2"></div>
            <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-w-4/6"></div>
          </div>
        </div>
        <div v-if="!loading" class="tw-flex tw-items-center tw-space-x-3">
          <h3 class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate">
            {{ user.email }}
          </h3>
          <!-- <span
            class="tw-flex-shrink-0 tw-inline-block tw-px-2 tw-py-0.5 tw-text-green-800 tw-text-xs tw-font-medium tw-bg-green-100 tw-rounded-full"
            >Admin</span> -->
        </div>
        <p
          v-if="!loading"
          class="tw-mt-1 tw-text-gray-500 tw-text-sm tw-truncate"
        >
        <span v-if="user.inviter_name">Invited by {{ user.inviter_name }}</span>
        <span v-else>Invited by risky.live</span>
        </p>
      </div>
      <div
        v-if="loading"
        class="tw-animate-pulse tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
      ></div>
      <avatar
        v-if="!loading"
        :username="user.email"
        :size="40"
        class="tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
      ></avatar>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  props: ["user", "loading"],
  components: {
    Avatar,
  },
};
</script>

<style>
</style>
