<template lang="html">
  <div class="">
    <div class="tw-border-b">
      <div class="tw-my-2">
          <Avatar
            :username="`${user.first_name} ${user.last_name}`"
            :customStyle="{
              width: '40vw',
              height: '40vw',
              maxHeight: '160px',
              maxWidth: '160px',
              margin: 'auto'
              }"
            class="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-mr-2 tw-bg-gray-300 tw-rounded-full"
          />
          <h3 class="tw-my-2 tw-text-lg tw-font-medium tw-text-gray-600">{{ user.full_name }}</h3>

      </div>
      <p class="tw-my-2 tw-flex tw-items-center">
        <icon :icon="['fal', 'envelope']" class="tw-w-6 tw-h-6 tw-text-gray-600" />
        <a
          :href="`mailto:${user.email}`"
          class="tw-ml-4 tw-text-base tw-font-normal tw-text-gray-500 hover:tw-text-gray-600"
        >
          {{ user.email }}
        </a>
      </p>
      <p class="tw-my-2 tw-flex tw-items-center">
        <icon :icon="['fal', 'phone-alt']" class="tw-w-6 tw-h-6 tw-text-gray-700" />
        <a
          :href="`tel:+${user.mobile}`"
          class="tw-ml-4 tw-text-base tw-font-normal tw-text-gray-500 hover:tw-text-gray-700"
        >
          {{ user.username }}
        </a>
      </p>
    </div>
    <div class="tw-mt-4">
      <UserRoleSelect :value="role || 'Guest'" @change="changeRole" />
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import UserRoleSelect from "./UserRoleSelect.vue";

export default {
  name: "TeamMemberEdit",
  components: {
    Avatar,
    UserRoleSelect
},
  props: ["user"],
  data() {
    return {
      role: this.user.role
    }
  },
  methods: {
    changeRole(newRole) {
      this.role = newRole;
    },
  }
};
</script>

<style lang="css" scoped></style>
