<template>
  <div class="tw-mt-10 lg:tw-divide-y lg:tw-divide-gray-200 tw-divide-none">
    <!-- <AboutCard v-if="tab === 1" class="tw-mb-6"/> -->
    <TeamInfoCard class="tw-mb-6" />
    <TeamEmergInfoCard class="tw-mb-6" />
    <SupportCard class="tw-mb-6" />
  </div>
</template>

<script>
import axios from "axios";
import UsersList from "@/components/Team/UsersList.vue";
import TeamProfileTabs from "@/components/Team/TeamProfileTabs.vue";
import InviteEmailCard from "@/components/Team/InviteEmailCard.vue";
import SupportCard from "@/components/Help/SupportCard.vue";
import AboutCard from "@/components/Help/AboutCard.vue";
import TeamInfoCard from "@/components/Team/TeamInfoCard.vue";
import TeamEmergInfoCard from "@/components/Team/TeamEmergInfoCard.vue";

export default {
  name: "team-users",
  components: {
    UsersList,
    TeamProfileTabs,
    InviteEmailCard,
    SupportCard,
    AboutCard,
    TeamInfoCard,
    TeamEmergInfoCard,
  },
  data() {
    return {
      tab: 1,
      invited_users: [],
    };
  },
  methods: {
    updateTab(val) {
      console.log("click");
      this.tab = val;
    },
    invitedAdd(e) {
      this.invited_users.push(e);
    },
    async getInvitedUsers() {
      try {
        let uri = process.env.VUE_APP_API_ROOT + "invitations/";
        var response = await this.$http.get(uri);
        this.invited_users = response.data.results;
        this.isLoading = false;
      } catch (e) {
        // handle the authentication error here
      }
    },
  },
  created() {
      this.getInvitedUsers()
  }
};
</script>

<style>
</style>
