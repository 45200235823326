<template>
  <div class="tw-bg-white tw-shadow sm:tw-rounded-lg">
    <div class="tw-px-4 tw-py-5 sm:tw-p-6">
      <h3 class="tw-text-lg tw-leading-6 tw-font-normal tw-text-gray-900">
        Invite a team member
      </h3>
      <div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
        <p>
          Enter the email address of the person you would like to invite to this
          team. We'll send them an email with a magic link to create their
          account.
        </p>
      </div>
      <div
        v-if="errors"
        class="tw-rounded-md tw-bg-red-50 tw-p-4 tw-max-w-xl tw-w-full"
      >
        <div class="tw-flex">
          <div class="tw-flex-shrink-0">
            <!-- Heroicon name: x-circle -->
            <svg
              class="tw-h-5 tw-w-5 tw-text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="tw-ml-3">
            <span v-for="(value, key) in errors" v-bind:key="key" class="tw-text-sm tw-font-medium tw-text-red-800">
              {{ value[0] }}
            </span>
          </div>
        </div>
      </div>
      <div class="tw-mt-5 sm:tw-flex sm:tw-items-center">
        <div class="tw-max-w-xs tw-w-full">
          <input
            v-model="email"
            @input="errors = ''"
            class="tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-py-2 tw-px-3 focus:tw-outline-none focus:tw-ring-orange-500 focus:tw-border-orange-500 sm:tw-text-sm"
            type="email"
            name="email"
            placeholder="someone@yourcompany.com.au"
          />
        </div>
        <button
          @click="submitForm()"
          :disabled="submitting"
          v-bind:class="[ submitting ? 'tw-opacity-50 tw-cursor-not-allowed' : '' ]"
          class="tw-mt-3 tw-w-full btn-orange sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
        >
          Send invite
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      errors: "",
      submitting: false,
    };
  },
  computed: {
    current_team() {
      return this.$store.getters['user/currentTeam'].id;
    },
  },
  methods: {
    submitForm() {
      if (this.submitting) return;
        this.errors = "";
      this.submitting = true;
      this.$http
        .post(
          process.env.VUE_APP_API_ROOT +
            "teams/" +
            this.current_team +
            "/invite_by_email/",
          this.$data
        )
        .then((response) => {
          this.email = "";
          this.$emit("invited", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          this.email = "";
          this.errors = error.response.data;
        })
        .finally(() => { this.submitting = false });
    },
  },
};
</script>

<style>
</style>
