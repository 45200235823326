var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"tw-border-b"},[_c('div',{staticClass:"tw-my-2"},[_c('Avatar',{staticClass:"tw-flex-shrink-0 tw-w-10 tw-h-10 tw-mr-2 tw-bg-gray-300 tw-rounded-full",attrs:{"username":`${_vm.user.first_name} ${_vm.user.last_name}`,"customStyle":{
            width: '40vw',
            height: '40vw',
            maxHeight: '160px',
            maxWidth: '160px',
            margin: 'auto'
            }}}),_c('h3',{staticClass:"tw-my-2 tw-text-lg tw-font-medium tw-text-gray-600"},[_vm._v(_vm._s(_vm.user.full_name))])],1),_c('p',{staticClass:"tw-my-2 tw-flex tw-items-center"},[_c('icon',{staticClass:"tw-w-6 tw-h-6 tw-text-gray-600",attrs:{"icon":['fal', 'envelope']}}),_c('a',{staticClass:"tw-ml-4 tw-text-base tw-font-normal tw-text-gray-500 hover:tw-text-gray-600",attrs:{"href":`mailto:${_vm.user.email}`}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1),_c('p',{staticClass:"tw-my-2 tw-flex tw-items-center"},[_c('icon',{staticClass:"tw-w-6 tw-h-6 tw-text-gray-700",attrs:{"icon":['fal', 'phone-alt']}}),_c('a',{staticClass:"tw-ml-4 tw-text-base tw-font-normal tw-text-gray-500 hover:tw-text-gray-700",attrs:{"href":`tel:+${_vm.user.mobile}`}},[_vm._v(" "+_vm._s(_vm.user.username)+" ")])],1)]),_c('div',{staticClass:"tw-mt-4"},[_c('UserRoleSelect',{attrs:{"value":_vm.role || 'Guest'},on:{"change":_vm.changeRole}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }