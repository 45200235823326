<template>
  <div
    class="tw-col-span-1 tw-my-5 tw-bg-white tw-rounded-lg tw-shadow tw-divide-y tw-divide-gray-200"
    @click="$emit('openuser', user)"
  >
    <div
      class="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-6 tw-space-x-6"
    >
      <div class="tw-flex-1 tw-truncate">
        <div
          v-if="loading"
          class="tw-animate-pulse tw-flex tw-items-center tw-space-x-3"
        >
          <div class="tw-max-w-sm tw-w-full">
            <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2"></div>
            <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-w-4/6"></div>
          </div>
        </div>
        <div v-if="!loading" class="tw-flex tw-items-center tw-space-x-3">
          <h3 class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate">
            {{ user.first_name }} {{ user.last_name }}
          </h3>
          <!-- <span
            class="tw-flex-shrink-0 tw-inline-block tw-px-2 tw-py-0.5 tw-text-green-800 tw-text-xs tw-font-medium tw-bg-green-100 tw-rounded-full"
            >Admin</span> -->
        </div>
        <p
          v-if="!loading"
          class="tw-mt-1 tw-text-gray-500 tw-text-sm tw-truncate"
        >
          {{ user.email }}
        </p>
      </div>
      <div
        v-if="loading"
        class="tw-animate-pulse tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
      ></div>
      <avatar
        v-if="!loading"
        :username="`${user.first_name} ${user.last_name}`"
        :size="40"
        class="tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
      ></avatar>
    </div>
    <div>
      <div class="tw--mt-px tw-flex tw-divide-x tw-divide-gray-200">
        <div class="tw-w-0 tw-flex-1 tw-flex">
          <div
            v-if="loading"
            class="tw-animate-pulse tw-relative tw--mr-px tw-w-0 tw-flex-1 tw-inline-flex tw-items-center tw-justify-center tw-py-4"
          >
            <div class="tw-w-20">
              <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2"></div>
            </div>
          </div>
          <a
            v-if="!loading"
            :href="`mailto:${user.email}`"
            @click.stop
            class="tw-relative tw--mr-px tw-w-0 tw-flex-1 tw-inline-flex tw-items-center tw-justify-center tw-py-4 tw-text-sm tw-text-gray-700 tw-font-medium tw-border tw-border-transparent tw-rounded-bl-lg hover:tw-text-gray-500"
          >
            <icon
              :icon="['fal', 'envelope']"
              class="tw-w-5 tw-h-5 tw-text-gray-400"
            />
            <span class="tw-ml-3">Email</span>
          </a>
        </div>
        <div class="tw--ml-px tw-w-0 tw-flex-1 tw-flex">
          <div
            v-if="loading"
            class="tw-animate-pulse tw-relative tw--mr-px tw-w-0 tw-flex-1 tw-inline-flex tw-items-center tw-justify-center tw-py-4"
          >
            <div class="tw-w-20">
              <div class="tw-h-4 tw-bg-gray-300 tw-rounded tw-mb-2"></div>
            </div>
          </div>
          <a
            v-if="!loading"
            :href="`tel:+${user.username}`"
            @click.stop
            class="tw-relative tw-w-0 tw-flex-1 tw-inline-flex tw-items-center tw-justify-center tw-py-4 tw-text-sm tw-text-gray-700 tw-font-medium tw-border tw-border-transparent tw-rounded-br-lg hover:tw-text-gray-500"
          >
            <icon
              :icon="['fal', 'phone-alt']"
              class="tw-w-5 tw-h-5 tw-text-gray-400"
            />
            <span class="tw-ml-3">Call</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  props: ["user", "loading"],
  components: {
    Avatar,
  },
};
</script>

<style>
</style>
