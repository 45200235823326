<template>
    <div>
        <h3 class="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Role</h3>

        <RadioGroup
            :selection="selection"
            :options="options"
            :id="'label'"
            @change="handleChange"
        >
            <template v-slot:option="{ option }">
                <div class="tw-flex tw-flex-col tw-ml-4">
                    <span
                        :class="
                            (checked(option.label)
                                ? 'tw-text-black '
                                : 'tw-text-gray-900 ') +
                            'tw-block tw-text-sm tw-font-medium'
                        "
                    >
                        {{ option.label }}
                    </span>
                    <span
                        :class="
                            (checked(option.label)
                                ? 'tw-text-gray-700 '
                                : 'tw-text-gray-500 ') + 'tw-block tw-text-sm'
                        "
                    >
                        {{ option.description }}
                    </span>
                </div>
            </template>
        </RadioGroup>
    </div>
</template>

<script>
import RadioGroup from '../ui/forms/RadioGroup.vue'

export default {
    name: 'UserRoleSelect',
    props: ["value"],
    components: {
        RadioGroup,
    },
    data() {
        const options = [
                {
                    label: 'Admin',
                    description: 'The user will have full admin access to this team',
                },
                {
                    label: 'Member',
                    description: 'The user can perform most functions',
                },
                { label: 'Guest', description: 'Restricted access ' },
            ]

        return {
            options,
            selection: options.find(item => item.label.toLocaleLowerCase() == this.value.toLocaleLowerCase()),
        }
    },
    methods: {
        checked(label) {
            if (this.selection) {
                return this.selection.label == label
            }
            return false
        },
        handleChange(selection) {
            this.selection = selection
            this.$emit('change', selection.label)
        },
    },
}
</script>
