<template>
  <div class="tw-mb-6">
    <div class="tw-border-b tw-border-gray-200">
      <nav class="tw--mb-px tw-flex tw-space-x-8">
        <a
          v-for="tab in tabs"
          :key="tab.id"
          href="#"
          class="group tw-inline-flex tw-items-center tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm"
          @click="$emit('update-tab', tab.id)"
          v-bind:class="textClasses(tab.id)"
        >
          <icon
            v-if="tab.icon"
            :icon="tab.icon"
            class="tw--ml-0.5 tw-mr-2 tw-h-5 tw-w-5"
            v-bind:class="iconClasses(tab.id)"
          />
          <span>{{ tab.label }}</span>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tab"],
  data() {
    return {
      tabsList: [
        { id: 1, label: "Event info", icon: null, flag: null },
        {
          id: 2,
          label: "Team members",
          icon: null,
          flag: null,
        },
      ],
    };
  },
  computed: {
    tabs() {
      return this.tabsList.filter((tab) => {
        //  NOTE: Can use feature flags here in future.
        return true;
      });
    },
  },
  methods: {
    textClasses(tab) {
      if (tab === this.tab) {
        return "tw-border-orange-500 tw-text-sm tw-text-orange-600";
      } else if (tab < this.tab) {
        return "tw-border-transparent tw-text-sm tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300";
      }
      return "tw-border-transparent tw-text-sm tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300";
    },
    iconClasses(tab) {
      if (tab === this.tab) {
        return "tw-text-orange-500";
      } else if (tab < this.tab) {
        return "tw-text-gray-400 group-hover:tw-text-gray-500";
      }
      return "tw-text-gray-400 group-hover:tw-text-gray-500";
    },
  },
};
</script>

<style>
</style>